/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import "./tmu.scss";

const materialDefault = new THREE.MeshPhysicalMaterial({
  color: "orange",
  roughness: 0.8,
});

export function TMU(props) {
  const { nodes, materials } = useGLTF("/models/tmu/TMU.glb");
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const materialLight = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 10,
  });

  const materialLight2 = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 0.5,
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      onClick={(event) => setActive(!active)}
      {...props}
      dispose={null}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <group
        position={[7.301, -4.126, 1.977]}
        rotation={[0.712, 1.598, -0.735]}
        scale={hovered ? [0.0025, 0.0021, 0.0025] : [0.002, 0.002, 0.002]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17002.geometry}
          material={props.lightModeCity ? materials["BOI 1"] : materialLight2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17002_1.geometry}
          material={props.lightModeCity ? materials["BOI 3"] : materialLight}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17002_2.geometry}
          material={materials["Blue TMU"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17002_3.geometry}
          material={materials.Yellow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17002_4.geometry}
          material={materials.White}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/TMU.glb");
