/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useRef } from "react";
import { Html, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import "./bg.scss";

export function BG(props) {
  const { nodes, materials } = useGLTF("/models/bg/BG.glb");
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const bloomRef = useRef();

  const materialLight = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 10,
  });

  const materialLight2 = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 0.5,
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      onClick={(event) => setActive(!active)}
      enabled={hovered}
      {...props}
      dispose={null}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <group
        position={[4.043, -5.194, 9.75]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={hovered ? [0.0017, 0.0021, 0.0023] : [0.0014, 0.002, 0.002]}
        ref={bloomRef}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001.geometry}
          material={props.lightModeCity ? materials["BOI 1"] : materialLight2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_1.geometry}
          material={props.lightModeCity ? materials["BOI 3"] : materialLight}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_2.geometry}
          material={materials["Blue TMU"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_3.geometry}
          material={materials["Light Paving"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_4.geometry}
          material={materials["Dark Blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_5.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_6.geometry}
          material={materials["Lighter Dark BLue"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/BG.glb");
