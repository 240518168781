/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import {
  Bloom,
  EffectComposer,
  SelectiveBloom,
} from "@react-three/postprocessing";
import { KernelSize } from "postprocessing";
import * as THREE from "three";

export function City(props) {
  const { nodes, materials } = useGLTF("/models/city/city.glb");
  const bloomRef = useRef();

  const materialDark = new THREE.MeshStandardMaterial({
    color: "black",
    transparent: false,
    opacity: 1,
  });

  const materialLight = new THREE.MeshStandardMaterial({
    color: "#0e10f1",
    transparent: true,
    opacity: 1,
    emissive: "#0e10f1",
    emissiveIntensity: 1,
  });

  const darkBlue = new THREE.MeshStandardMaterial({
    color: "#161F44",
  });

  return (
    <group {...props} dispose={null}>
      <group
        position={[9.718, -2.291, -0.384]}
        rotation={[-Math.PI, -Math.PI / 2, 0]}
        scale={0.42}
      >
        {!props.lightModeCity && (
          <EffectComposer>
            <SelectiveBloom
              kernelSize={1}
              selection={[bloomRef]}
              luminanceThreshold={0}
              intensity={0.7}
            />
            <pointLight
              castShadow
              intensity={70}
              color={"#522dee"}
              position={[-6, -3, 10]}
              shadow-bias={-0.0001}
              shadow-camera-left={-19}
              shadow-camera-right={19}
              shadow-camera-top={19}
              shadow-camera-bottom={-19}
              shadow-mapSize-width={2048}
              shadow-mapSize-height={2048}
            ></pointLight>
            <pointLight
              castShadow
              intensity={50}
              color={"#522dee"}
              position={[10, -3, 12]}
              shadow-mapSize-width={512}
              shadow-mapSize-height={512}
            ></pointLight>
          </EffectComposer>
        )}

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_1.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_2.geometry}
          material={
            props.lightModeCity ? materials["Building blue"] : materialLight
          }
          ref={bloomRef}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_3.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialDark
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_4.geometry}
          material={props.lightModeCity ? materials["Blue Road"] : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_5.geometry}
          material={materials.Yellow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text001_6.geometry}
          material={materials["Blue TMU"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/city.glb");
