/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useRef } from "react";
import { Html, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import "./soti.scss";

const materialDefault = new THREE.MeshPhysicalMaterial({
  color: "orange",
  roughness: 0.8,
});

export function Soti(props) {
  const { nodes, materials } = useGLTF("/models/soti/SOTI.glb");
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);

  const bloomRef = useRef();

  const materialLight = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 10,
  });

  const materialLight2 = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 0.5,
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      onClick={(event) => setActive(!active)}
      {...props}
      dispose={null}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <group
        position={hovered ? [-8.887, -3.199, -3.761] : [-8.687, -3.199, -3.761]}
        scale={hovered ? [0.883, 0.689, 0.739] : [0.823, 0.679, 0.679]}
        ref={bloomRef}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text.geometry}
          material={materials["Dark Blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text_1.geometry}
          material={props.lightModeCity ? materials["BOI 1"] : materialLight2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text_2.geometry}
          material={props.lightModeCity ? materials["BOI 3"] : materialLight}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text_3.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text_4.geometry}
          material={materials["Lighter Dark BLue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text_5.geometry}
          material={materials["Light Paving"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/SOTI.glb");
