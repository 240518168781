/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

const materialDefault = new THREE.MeshPhysicalMaterial({
  color: "orange",
  roughness: 0.8,
});

export function MLH(props) {
  const { nodes, materials } = useGLTF("/models/mlh/MLH.glb");
  const [hovered, setHover] = useState(false);

  const materialLight = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 10,
  });

  const materialLight2 = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 0.5,
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      {...props}
      dispose={null}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <group
        position={[-2.402, -3.493, -6.52]}
        rotation={[Math.PI / 2, 0, -1.593]}
        scale={hovered ? [0.571, 0.35, 0.571] : [0.531, 0.33, 0.521]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002_1.geometry}
          material={materials["Yellow MLH"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002_2.geometry}
          material={materials["Blue TMU"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002_3.geometry}
          material={props.lightModeCity ? materials["BOI 1"] : materialLight2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002_4.geometry}
          material={props.lightModeCity ? materials["BOI 3"] : materialLight}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text002_5.geometry}
          material={materials["Light Paving"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/MLH.glb");
