/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect } from "react";
import { Html, useGLTF } from "@react-three/drei";
import * as THREE from "three";

export function ClickSupport(props) {
  const { nodes, materials } = useGLTF("/models/bg/BG.glb");

  return (
    <group {...props} dispose={null}>
      <group
        position={[4.043, -5.194, 9.75]}
        rotation={[-Math.PI, 0, -Math.PI]}
        scale={[0.0017, 0.0021, 0.0023]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001.geometry}
          material={materials["BOI 1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_1.geometry}
          material={materials["BOI 3"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_2.geometry}
          material={materials["Blue TMU"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_3.geometry}
          material={materials["Light Paving"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_4.geometry}
          material={materials["Dark Blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_5.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_17001_6.geometry}
          material={materials["Lighter Dark BLue"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/BG.glb");
