/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useState, useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

export function RPL(props) {
  const { nodes, materials } = useGLTF("/models/rpl/RPL.glb");
  const [hovered, setHover] = useState(false);

  const materialLight = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 10,
  });

  const materialLight2 = new THREE.MeshStandardMaterial({
    color: "green",
    transparent: true,
    opacity: 1,
    emissive: "red",
    emissiveIntensity: 0.5,
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  return (
    <group
      {...props}
      dispose={null}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}
    >
      <group
        position={hovered ? [-1.31, -0.707, 1.923] : [-1.24, -0.707, 1.843]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={hovered ? [0.265, 0.354, 0.304] : [0.245, 0.304, 0.304]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003_1.geometry}
          material={materials["Dark Blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003_2.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003_3.geometry}
          material={materials["Yellow MLH"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003_4.geometry}
          material={props.lightModeCity ? materials["BOI 1"] : materialLight2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Text003_5.geometry}
          material={props.lightModeCity ? materials["BOI 3"] : materialLight}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/RPL.glb");
