/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export function TrainTwo(props) {
  const { nodes, materials } = useGLTF("/models/train/train.glb");

  const myMesh = React.useRef();

  const materialDark = new THREE.MeshStandardMaterial({
    color: "black",
    transparent: false,
    opacity: 0.9,
  });

  const materialLight = new THREE.MeshStandardMaterial({
    color: "#0e10f1",
    transparent: true,
    opacity: 1,
    emissive: "#0e10f1",
    emissiveIntensity: 1,
  });

  const darkBlue = new THREE.MeshStandardMaterial({
    color: "#161F44",
  });

  useFrame(() => {
    if (myMesh.current.position.x > -70) {
      myMesh.current.position.x -= 0.25;
    }
    if (myMesh.current.position.x < -60) {
      myMesh.current.position.x = 40;
    }
  });

  return (
    <group {...props} dispose={null} ref={myMesh}>
      <group position={[-5.064, -3.423, 6.202]} scale={0.298}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialLight
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_1.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_2.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_3.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_4.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialLight
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_5.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_6.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialLight
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_7.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_8.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_9.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_10.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialLight
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_11.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_12.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_13.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_14.geometry}
          material={
            props.lightModeCity ? materials["Light Paving"] : materialLight
          }
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_15.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_16.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_17.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_18.geometry}
          material={materials["Building blue"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder033_19.geometry}
          material={materials["Building blue"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/train.glb");
