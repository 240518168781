/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

export function Car(props) {
  const { nodes, materials } = useGLTF("/models/car/car.glb");

  const myMesh = React.useRef();

  const materialDark = new THREE.MeshStandardMaterial({
    color: "black",
    transparent: false,
    opacity: 0.9,
  });

  const materialLight = new THREE.MeshStandardMaterial({
    color: "#0e10f1",
    transparent: true,
    opacity: 1,
    emissive: "#0e10f1",
    emissiveIntensity: 1,
  });

  const darkBlue = new THREE.MeshStandardMaterial({
    color: "#161F44",
  });

  useFrame(() => {
    if (myMesh.current.position.z < 30) {
      myMesh.current.position.z += 0.04;
    }
    if (myMesh.current.position.z > 30) {
      myMesh.current.position.z = -10;
    }
  });
  return (
    <group {...props} dispose={null} ref={myMesh}>
      <group
        position={[10.813, -4.129, -6.456]}
        rotation={[-Math.PI, 0, 0]}
        scale={-0.117}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={props.lightModeCity ? darkBlue : materialDark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube030_1.geometry}
          material={
            props.lightModeCity ? materials["Building blue"] : materialLight
          }
        />
      </group>
    </group>
  );
}

useGLTF.preload("/car.glb");
