import "./App.scss";
import NavBar from "../../components/NavBar/NavBar.js";
import React, { Suspense, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import * as THREE from "three";
import {
  OrbitControls,
  Preload,
  Loader,
  OrthographicCamera,
} from "@react-three/drei";
import { City } from "../../components/City/city";
import { Train } from "../../components/Train/train";
import { BG } from "../../components/BG/bg";
import { RPL } from "../../components/RPLA/rpl";
import { Soti } from "../../components/SOTI/soti";
import { TMU } from "../../components/TMU/tmu";
import { MLH } from "../../components/MLH/mlh";
import { TrainTwo } from "../../components/TrainTwo/train2";
import { Bus } from "../../components/Bus/bus";
import { Car } from "../../components/Car/car";
import { CarTwo } from "../../components/CarTwo/carTwo";
import CloseSvg from "../../resources/circle-xmark-regular.svg";
import Clicker from "../../resources/Clicker.gif";
import Light from "../../resources/brightness.png";
import Dark from "../../resources/dark-mode.png";
import plantEd from "../../resources/PlantEd_Gif.gif";
import resumeDark from "../../resources/resume-dark.png";
import resumeLight from "../../resources/resume-light.png";
import { ClickSupport } from "../../components/BuildingClickSupport/clickSupport";
import { TMU2015 } from "../../components/TimeMarkers/TMU2015";
import { BG2018 } from "../../components/TimeMarkers/BG2018";
import { MLH2019 } from "../../components/TimeMarkers/MLH2019";
import { RPL2019 } from "../../components/TimeMarkers/RPL2019";
import { SOTI2021 } from "../../components/TimeMarkers/SOTI2021";

const CameraDolly = ({ isZoom }) => {
  const vec = new THREE.Vector3();

  useFrame((state) => {
    const step = 0.1;
    const x = isZoom ? 0 : 5;
    const y = isZoom ? 10 : 5;
    const z = isZoom ? 10 : 5;

    state.camera.position.lerp(vec.set(x, y, z), step);
    state.camera.updateProjectionMatrix();
  });

  return null;
};

function Box(props) {
  const mesh = useRef();
  return (
    <mesh {...props} ref={mesh}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial visible={false} color={"orange"} />
    </mesh>
  );
}

var zoom;
calcSceneStart2();
window.addEventListener("resize", calcSceneStart2);
function calcSceneStart2() {
  if (window.matchMedia("(max-width: 480px)").matches) {
    zoom = 30;
  } else {
    zoom = 65;
  }
}

function App() {
  const [isZoom] = useState(false);

  function openResume(url) {
    window.open(url);
  }

  function closeEntryPage() {
    showEntryPage(false);
    showEntryFade(true);
    showClickerFade(true);
    const timeout = setTimeout(() => {
      showEntryFade(false);
    }, 2000);
    const clickerTimeout = setTimeout(() => {
      showClickerFade(false);
    }, 10000);
  }

  function closeTMUPage() {
    showTMUPage(false);
  }

  function closeBGPage() {
    showBGPage(false);
  }

  function closeRPLPage() {
    showRPLPage(false);
  }

  function closeMLHPage() {
    showMLHPage(false);
  }

  function closeSOTIPage() {
    showSOTIPage(false);
  }

  function toggleLightMode() {
    lightMode ? showLightMode(false) : showLightMode(true);
  }

  const [entryShowing, showEntryPage] = useState(true);
  const [entryFade, showEntryFade] = useState(false);
  const [clickerFade, showClickerFade] = useState(false);

  const [lightMode, showLightMode] = useState(true);

  const [tmuShowing, showTMUPage] = useState(false);
  const [bgShowing, showBGPage] = useState(false);
  const [rplShowing, showRPLPage] = useState(false);
  const [mlhShowing, showMLHPage] = useState(false);
  const [sotiShowing, showSOTIPage] = useState(false);

  return (
    <div class="screen">
      <div>
        {entryShowing && (
          <div className="entry-container">
            <div className="contents-container">
              <label className="entry-text-header">
                WELCOME TO MY PORTFOLIO!
              </label>
              <a
                href="https://drive.google.com/file/d/1EzxolJJMX8DePgwNdAcTgbFhzrSBVihY/view?usp=drive_link"
                className="button-style"
              >
                <p className="button-text-style"> View Resume </p>
              </a>
              <button onClick={closeEntryPage} className="button-style">
                <p className="button-text-style"> See 3D World </p>
              </button>

              <label className="entry-text-description">
                My name is Matthew and I'm passionate about creative products
                and human oriented design. I have always enjoyed learning about
                how things are made and the details behind combining art with
                practicality.
              </label>
            </div>
          </div>
        )}
      </div>
      <div>
        {entryFade && (
          <div className="fade-container">
            <div className="contents-container">
              <label className="entry-text-header">
                WELCOME TO MY PORTFOLIO!
              </label>
              <a
                href="https://drive.google.com/file/d/1EzxolJJMX8DePgwNdAcTgbFhzrSBVihY/view?usp=drive_link"
                className="button-style"
              >
                <p className="button-text-style"> View Resume </p>
              </a>
              <button className="button-style">
                <p className="button-text-style"> See 3D World </p>
              </button>

              <label className="entry-text-description">
                My name is Matthew and I'm passionate about creative products
                and human oriented design. I have always enjoyed learning about
                how things are made and the details behind combining art with
                practicality.
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="page-container">
        {tmuShowing && (
          <div className="bg-container">
            <div className="expanding-background-container">
              <div className="expanding-background"></div>
            </div>
            <div className="icon-contents-container">
              <img
                onClick={closeTMUPage}
                className="close-icon"
                src={CloseSvg}
                alt="Close"
              />
            </div>
            <div className="bg-contents-container">
              <label className="info-header top-padding-large left-align">
                Toronto Metropolitan University
              </label>
              <label className="info-page-text top-padding-small ">
                During my time at Toronto Metropolitan University (TMU), I found
                the perfect fit for my love of creativity and problem-solving by
                studying Architecture. It just felt right to combine my artistic
                side with my knack for figuring things out.
              </label>
              <label className="info-page-text top-padding-small">
                TMU taught me a lot. I got good at managing my time, which
                helped me handle all the different tasks like coursework, design
                work, and improving my skills over time. I also learned about
                iterative design, a process of trying things out, refining them,
                and making them better step by step.
              </label>
              <label className="info-page-text top-padding-small ">
                The projects I tackled in my studies were a lot like the
                challenges I face in my current job working on software. They
                needed research, planning, and a lot of creative
                problem-solving. It's funny how similar designing a building and
                designing a software program can be.
              </label>
              <label className="info-page-text top-padding-small ">
                One of the highlights was getting the Ontario Association of
                Architects award in my third year. This award recognized me for
                having the highest grades among my peers. It was a proud moment
                that showed me my hard work was paying off.
              </label>
              <label className="info-page-text top-padding-small bottom-padding-immense">
                As I look back at my time in TMU, I realize how much I've
                gained. From boosting my creative thinking to mastering
                step-by-step improvement in designs, from collaborating with
                diverse teams to untangling tricky problems, TMU not only
                prepared me for an architectural path but also shaped my curious
                and motivated approach to my career journey.
              </label>
            </div>
          </div>
        )}
        {bgShowing && (
          <div className="bg-container">
            <div className="expanding-background-container">
              <div className="expanding-background"></div>
            </div>
            <div className="icon-contents-container">
              <img
                onClick={closeBGPage}
                className="close-icon"
                src={CloseSvg}
                alt="Close"
              />
            </div>
            <div className="bg-contents-container">
              <label className="info-header top-padding-large left-align">
                Barry Goldman Architect
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Successfully acquired building permits for 10+ commercial and
                residential building projects in the GTA by leading projects
                from initial design to construction
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Exceeded client expectations by closely collaborating to
                define their specific requirements. Executed precise technical
                drawings using AutoCAD and Revit software, and effectively
                engaged technical consultants to ensure comprehensive project
                fulfillment
              </label>
              <hr className="hr-rule"></hr>
              <label className="info-page-text top-padding-small ">
                During my fourth year of school, I had the amazing opportunity
                to do a 16-month internship at Barry Goldman Architect. This was
                a really important time for me because it let me see how things
                work in the real world of architecture.
              </label>
              <label className="info-page-text top-padding-small">
                During this time, I got to work on projects involving houses in
                different parts of the Greater Toronto Area. It was great to be
                part of creating buildings and to see how ideas turn into real
                spaces that people use.
              </label>
              <label className="info-page-text top-padding-small ">
                One of the things I did a lot was talking to important clients
                about their design choices for their homes. This taught me how
                important it is to explain ideas clearly and make sure that what
                we're designing fits what the clients want.
              </label>
              <label className="info-page-text top-padding-small ">
                I also learned how to work closely with other experts like
                engineers who know about things like heating, cooling, and the
                structure of buildings. This teamwork showed me that creating a
                building involves a lot of different skills coming together.
              </label>
              <label className="info-page-text top-padding-small bottom-padding-immense">
                When I think back to my time at Barry Goldman Architect, I
                realize how much I've learned. From working on real projects to
                talking to clients, from collaborating with different experts to
                managing projects, this experience gave me a solid foundation as
                I continue my career.
              </label>
            </div>
          </div>
        )}
        {rplShowing && (
          <div className="bg-container">
            <div className="expanding-background-container">
              <div className="expanding-background"></div>
            </div>
            <div className="icon-contents-container">
              <img
                onClick={closeRPLPage}
                className="close-icon"
                src={CloseSvg}
                alt="Close"
              />
            </div>
            <div className="bg-contents-container">
              <label className="info-header top-padding-large left-align">
                Rebanks Pepper Littlewood Architect
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Aided in acquisition and execution of a $80-million CAD joint
                venture building project for the Durham Regional Police by
                working closely with the CEO and Parkin Architects to design and
                develop plans and documentation for the project
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Continuously enhanced multiple Toronto Community Housing
                service hubs by working in tandem with the in-house design team
                to consistently provide technically feasible plans for large
                scale projects resulting in upgrades to 9+ service hubs and over
                500 individual resident unit improvements
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Facilitated universal design requirements by researching local
                building codes and coordinating with universal hardware service
                providers resulting in city and region permit approval
              </label>
              <hr className="hr-rule"></hr>
              <label className="info-page-text top-padding-small ">
                During my time at Rebanks Pepper Littlewood Architects, I went
                from being an intern while finishing up my studies in
                architecture to working on some interesting projects. At first,
                I helped with fixing up homes in Toronto Community Housing. It
                was cool to see how design could make a difference in people's
                lives.
              </label>
              <label className="info-page-text top-padding-small">
                After I graduated, I got to work on bigger projects, especially
                ones for the Durham Police. The most exciting one was a project
                with a budget of $80 million. It involved building three large
                police buildings and many other required ancillary spaces. I was
                part of a small team of three, and we had to figure out how to
                make everything work with the money we had and manage our time
                well to finish all the detailed plans and construction
                documentation.
              </label>
              <label className="info-page-text top-padding-small ">
                Working together was a big part of making things happen. Our
                team of three brought different skills and ideas, and by putting
                them all together, we could solve problems and come up with
                smart solutions. We had to be careful with time too, making sure
                we stayed on track with our schedules.
              </label>
              <label className="info-page-text top-padding-small bottom-padding-immense">
                Looking back on my time at Rebanks Pepper Littlewood Architects,
                I see how much I've grown. What I've learned is that
                architecture isn't just about making buildings look good – it's
                about making spaces that work well for people's lives. I'm proud
                to have been part of that process during my time here. The work
                ethic and my love for solving problems carries over into all the
                work I do now.
              </label>
            </div>
          </div>
        )}
        {mlhShowing && (
          <div className="bg-container">
            <div className="expanding-background-container">
              <div className="expanding-background"></div>
            </div>
            <div className="icon-contents-container">
              <img
                onClick={closeMLHPage}
                className="close-icon"
                src={CloseSvg}
                alt="Close"
              />
            </div>
            <div className="bg-contents-container">
              <label className="info-header top-padding-large left-align">
                Major League Hacking
              </label>
              <label className="info-page-text top-padding-small">
                While working as a junior at an architecture firm in my last
                year of university, I found myself using the free time I had to
                participate in hackathons. Developing apps, learning new coding
                languages, and trying to make software appealed to me. I
                enrolled in hackathons with MLH, the official student hackathon
                league.
              </label>
              <hr className="hr-rule"></hr>
              <label className="info-header top-padding-small left-align">
                MapWatch
              </label>
              <label className="info-page-text top-padding-small left-align">
                Mapwatch aims to educate and connect people who enjoy
                exploration, animals, and photography. Since we have all been
                inside lately due to the pandemic, we wanted to build an app
                that would encourage people to responsibly explore the wildlife
                of their neighborhoods or cities during the summertime. This app
                was submitted to Surfs Up Hacks 2021 and Won First Wave Hack.
              </label>
              <label className="info-page-text top-padding-small bottom-padding-medium left-align">
                Inspired by days on the beach where you would rather explore
                than sit in the sun. Apps like geocaching and Pokemon Go
                inspired the design. Mapwatch allows you and everyone on the app
                to upload a photo and description of an animal you saw in any
                geographical location.
              </label>
              <div className="image-holder">
                <iframe
                  height={300}
                  className="info-images"
                  src="https://www.youtube.com/embed/EPyuTuhtIBg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <label className="info-page-text top-padding-medium  left-align">
                We used the Flutter framework to create Mapwatch, so that it's
                available on both Android and iOS. The developers in the team
                are very new to using Flutter, so this was a challenge for us,
                as well as a great opportunity to learn something new.
                Additionally, we also used Cloud Firestore to store all of our
                data from the front-end. For Google Cloud, we wanted to make
                sure that every code that we sent to our shared repository was
                working properly. In order to do that, we created a Compute
                Engine instance on Google Cloud Console. Using 3 GitHub
                self-hosted runners that we configured manually onto the virtual
                machine, our code was continuously checked for any build errors.
                This build process was determined by the CI pipeline script that
                we also designed ourselves.
              </label>
              <hr className="hr-rule"></hr>
              <label className="info-header top-padding-small left-align">
                PlantEd
              </label>
              <label className="info-page-text top-padding-small bottom-padding-medium left-align">
                PlantEd is a plant care app for both casual and knowledgeable
                plant parents that makes botanical information accessible to the
                every day user. PlantEd provides a way for both new and
                experienced plant parents to connect and share tips about the
                plants they have in common. The final app was submitted to
                Garden Hacks 2021.
              </label>
              <div className="image-holder">
                <img
                  className="info-images "
                  src={plantEd}
                  alt="Plant Ed Mockup of app"
                />
              </div>
              <label className="info-page-text top-padding-medium bottom-padding-small left-align">
                We were inspired by our friends who own gardens and/or indoor
                plant collections. With this in mind, we wanted to create an app
                that would help them organize their plant collections and get
                useful information about them. Thus, PlantEd was born.
              </label>
              <label className="info-page-text bottom-padding-medium left-align">
                For the wireframes, brainstorming and creation of the
                illustrations, we used Figma and Procreate. We developed PlantEd
                using the Flutter framework and used the Pl@ntNet API for the
                camera scanning feature. For authentication, we use Google
                Cloud's Firebase Authentication SDK to validate and create user
                accounts. We also use their Compute Engine virtual machines to
                host GitHub runners to make sure that every new code builds
                properly.
              </label>
            </div>
          </div>
        )}
        {sotiShowing && (
          <div className="bg-container">
            <div className="expanding-background-container">
              <div className="expanding-background"></div>
            </div>
            <div className="icon-contents-container">
              <img
                onClick={closeSOTIPage}
                className="close-icon"
                src={CloseSvg}
                alt="Close"
              />
            </div>
            <div className="bg-contents-container">
              <label className="info-header top-padding-large left-align">
                SOTI
              </label>

              <label className="info-page-text top-padding-small ">
                ⦿ Developing a mobile device management signaling system using
                .NET, Angular, and REST APIs that provides organizations with
                increased control over their fleet of mobile devices resulting
                in reduced down time and saving organizations over $68,000 USD
                by automating device notifications
              </label>
              <label className="info-page-text top-padding-small">
                ⦿ Successfully improved the scalability of SOTI’s internal
                development teams, by assisting with the creation and adoption
                of a shared internal library of dynamic Angular components,
                resulting in better code readability and reduced development
                time throughout the company
              </label>
              <label className="info-page-text top-padding-small ">
                ⦿ Optimized project performance by implementing incremental
                Angular version upgrades, resulting in a 20% reduction in build
                times{" "}
              </label>
              <hr className="hr-rule"></hr>
              <label className="info-page-text top-padding-small ">
                My time at SOTI has been quite the journey. I started things off
                as a Quality Assurance specialist, making sure our software was
                void of bugs and met all expectations. This gave me a fantastic
                grasp of the details of the product. During my time as a QA, my
                passion for the frontend was recognized, and I jumped into
                software development headfirst.
              </label>
              <label className="info-page-text top-padding-small">
                Now, I'm a full stack developer coding in TypeScript and C# and
                work with GHE pipelines and repository systems. My latest work
                involves the core alerts feature for our very own Mobile Device
                Management software. I work in typescript to manage software
                integration and interface. I then move to C# to work out how our
                data is managed, converted, and stored in our product database.
              </label>
              <label className="info-page-text top-padding-small ">
                I've also got my hands full with keeping our Angular versions
                updated. Serving as the go-to authority for front-end queries
                within our intricate codebase, I try to foster a collaborative
                environment where knowledge sharing, and innovation thrive.
              </label>
              <label className="info-page-text top-padding-small ">
                I'm part of an awesome crew of 15 talented folks. We're all
                about working smart and use the Agile management methodology.
                Our daily scrum meetings keep us on track, making sure we're in
                sync and ready to tackle anything that comes our way.
              </label>
              <label className="info-page-text top-padding-small bottom-padding-immense">
                My time at SOTI stands as a testament to my passion for
                software, my willingness to grow in my career and learn new
                technology. I’m excited for the continued journey of growth and
                innovation, both personally and collectively.
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="dark-container" onClick={toggleLightMode}>
        {lightMode && <img className="dark-toggle" src={Dark} alt="Click" />}
        {!lightMode && <img className="light-toggle" src={Light} alt="Click" />}
      </div>
      <div className="resume-dark-container">
        {lightMode && (
          <a
            className="resume-dark-toggle"
            src={resumeDark}
            href="https://drive.google.com/file/d/1EzxolJJMX8DePgwNdAcTgbFhzrSBVihY/view?usp=drive_link"
            alt="Click"
          >
            <img className="resume-icon" src={resumeDark} alt="Click" />
          </a>
        )}
        {!lightMode && (
          <a
            className="resume-light-toggle"
            src={resumeDark}
            href="https://drive.google.com/file/d/1EzxolJJMX8DePgwNdAcTgbFhzrSBVihY/view?usp=drive_link"
            alt="Click"
          >
            <img className="resume-icon" src={resumeLight} alt="Click" />
          </a>
        )}
      </div>

      <div>
        {clickerFade && (
          <div className="fade-container-clicker">
            <img className="clicker" src={Clicker} alt="Click" />
            <div className="clicker-background"></div>
          </div>
        )}
      </div>
      <div className={`${lightMode ? "container-light" : "container-dark"}`}>
        <Canvas
          shadows
          shadowMap
          camera={{ position: [35, 15, 40] }}
          lookAt={[0, -50, 0]}
          dpr={1}
        >
          <Suspense fallback={null}>
            <OrthographicCamera makeDefault zoom={zoom} />
            <OrbitControls
              enablePan={false}
              enableZoom={false}
              maxDistance={55}
              minDistance={40}
              maxPolarAngle={1}
              minAzimuthAngle={0.7}
              maxAzimuthAngle={1}
            />

            {lightMode && (
              <directionalLight
                intensity={3}
                position={[39, 27, -25]}
                color="#ffffff"
                castShadow
                shadow-bias={-0.0001}
                shadow-camera-left={-19}
                shadow-camera-right={19}
                shadow-camera-top={19}
                shadow-camera-bottom={-19}
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
              />
            )}
            <Box
              position={[1.4, 0.5, -0.3]}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <City position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <Train position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <TrainTwo
              position={[0, 3, -0.5]}
              scale={1}
              lightModeCity={lightMode}
            />
            <Bus position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <Car position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <CarTwo position={[0, 3, 10]} scale={1} lightModeCity={lightMode} />

            {lightMode && <fog attach="fog" args={["#ffffff", 43, 55]} />}
            {!lightMode && <fog attach="fog" args={["black", 40, 53]} />}
            {lightMode && <ambientLight intensity={1} />}
            {!lightMode && <ambientLight intensity={0.8} />}

            <TMU2015
              onClick={showTMUPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            ></TMU2015>
            <BG2018
              onClick={showBGPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            ></BG2018>
            <MLH2019
              onClick={showMLHPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            ></MLH2019>
            <RPL2019
              onClick={(e) => {
                e.stopPropagation();
                showRPLPage(true);
              }}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            ></RPL2019>
            <SOTI2021
              onClick={showSOTIPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            ></SOTI2021>

            <MLH position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <ClickSupport
              onClick={showMLHPage}
              position={[-7.8, 0.74, -20.84]}
              scale={[1.1, 0.5, 1.4]}
            />
            <RPL position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <ClickSupport
              onClick={showRPLPage}
              position={[-6.33, 11.7, -3.5]}
              scale={[1.4, 3, 0.5]}
            />
            <Soti position={[0, 3, 0]} scale={1} lightModeCity={lightMode} />
            <ClickSupport
              onClick={showSOTIPage}
              position={[-17.4, 2, -17.155]}
              scale={[2.4, 0.8, 1.3]}
            />
            <TMU
              onClick={showTMUPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            />
            <BG
              onClick={showBGPage}
              position={[0, 3, 0]}
              scale={1}
              lightModeCity={lightMode}
            />

            <Preload all />
          </Suspense>
          <CameraDolly isZoom={isZoom} />
        </Canvas>
        {!entryShowing && <Loader />}
      </div>
    </div>
  );
}

export default App;
