/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function MLH2019(props) {
  const myMesh = React.useRef();

  useFrame(() => {
    //up
    if (
      myMesh.current.position.y < 3.4 &&
      myMesh.current.position.y > 3.25 &&
      myMesh.current.position.x !== 0.00002
    ) {
      myMesh.current.position.y += 0.003;
    }
    if (
      myMesh.current.position.y < 3.25 &&
      myMesh.current.position.y > 3.05 &&
      myMesh.current.position.x !== 0.00002
    ) {
      myMesh.current.position.y += 0.005;
    }
    if (
      myMesh.current.position.y < 3.05 &&
      myMesh.current.position.y > 2.9 &&
      myMesh.current.position.x !== 0.00002
    ) {
      myMesh.current.position.y += 0.003;
    }

    //down
    if (
      myMesh.current.position.y < 3.4 &&
      myMesh.current.position.y > 3.25 &&
      myMesh.current.position.x == 0.00002
    ) {
      myMesh.current.position.y -= 0.003;
    }
    if (
      myMesh.current.position.y < 3.25 &&
      myMesh.current.position.y > 3.05 &&
      myMesh.current.position.x == 0.00002
    ) {
      myMesh.current.position.y -= 0.005;
    }
    if (
      myMesh.current.position.y < 3.05 &&
      myMesh.current.position.y > 2.9 &&
      myMesh.current.position.x == 0.00002
    ) {
      myMesh.current.position.y -= 0.003;
    }

    // direction
    if (myMesh.current.position.y > 3.35) {
      myMesh.current.position.x = 0.00002;
    }
    if (myMesh.current.position.y < 2.95) {
      myMesh.current.position.x = 0;
    }
  });

  const { nodes, materials } = useGLTF("/models/TimeMarkers/MLH2019.glb");
  return (
    <group {...props} dispose={null} ref={myMesh}>
      <group
        position={[-2.676, -1.699, -6.536]}
        rotation={[Math.PI / 2, 0, -0.982]}
        scale={[0.435, 0.308, 0.38]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube003_1.geometry}
          material={materials["Dark Blue.003"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/MLH2019.glb");
